import { Component, OnInit } from '@angular/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ProductsService } from '../../../../../shared/services/products.service';
import { MessageService } from 'primeng/api';
import { StoreChangesService } from 'projects/shared/services/store-changes.service';
// import { UserService } from '../../../../../shared/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  statistics = [];
  loginMessageAfter!: string;
  showToast = false
  endsubs$: Subject<any> = new Subject();

  constructor(
    // private userService: UserService,
    private productService: ProductsService,
    private messageService: MessageService,
    private storeChangesServ: StoreChangesService,
  ) {}

  ngOnInit(): void {
  this.loginMessage()
    // combineLatest([
    //   this.ordersService.getOrdersCount(),
    //   this.productService.getProductsCount(),
    //   this.userService.getUsersCount(),
    //   this.ordersService.getTotalSales()
    // ])
    //   .pipe(takeUntil(this.endsubs$))
    //   .subscribe((values) => {
    //     this.statistics = values;
    //   });
  }
  ngAfterViewInit(): void {
    if (this.loginMessageAfter != '') {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.loginMessageAfter });
    }
  }
  loginMessage() {
    this.storeChangesServ.getloginMessage().subscribe((response) => {
      this.loginMessageAfter = response
      if (this.loginMessageAfter == '') {
        setTimeout(() => {
          this.showToast = false
        }, 5000);
      } else {
        this.showToast = true
      }
      console.log(this.loginMessageAfter)
    });
  }

  ngOnDestroy() {
    // this.endsubs$.next();
    // this.endsubs$.complete();
  }

}
